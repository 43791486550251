<template>
  <v-layout row justify-space-between class="mt--5 mb--25">
    <v-flex
      style="z-index: 100; !important"
      sm2
      md2
      lg1
      xl1
      v-for="(brand, i) in brandImages"
      :key="i"
    >
      <a :href="brand.url" target="_blank">
        <img :style="brand.id === 1 ? 'margin-left: 25%' : 'margin-left: 0%'" width="80" :src="brand.src" alt="Logo Images"
      /></a>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      brandImages: [
        {
          id: 1,
          src: require("../../assets/images/service/healthspot_logo.svg"),
          url: "https://healthspot.hhg.gr/",
        },
        {
          id: 2,
          src: require("../../assets/images/service/digitalclinic_logo.svg"),
          url: "https://www.digitalclinic.gr/el/",
        },
        // {
        //   src: require("../../assets/images/service/hhg_logo.svg"),
        //   url: "https://www.hhg.gr/el/",
        // },
      ],
    };
  },
};
</script>
