<template>
  <!-- <v-layout row justify-space-between class="mt--30 mb--30"> -->
  <v-layout row justify-space-between>
    <v-flex
      style="z-index: 100; !important"
      class="mt--20 mb--20 d-flex"
      sm1
      md1
      lg1
      xl1
      v-for="brand in brandImages"
      :key="brand.key"
    >
      <a style="width:100%" :key="brand.key" :href="brand.url" target="_blank" class="d-flex justify-center">
        <img :src="brand.src" alt="Logo Images" class="pl-1 brand-img" :style="brand.style"
      /></a>
    </v-flex>
    <v-flex
      style="z-index: 100; !important"
      class="mt--15 mt_lg--10 mt_md--25 mb--30 d-flex justify-start align-center"
      sm2
      md2
      lg2
      xl2
      v-for="brand1 in brandImage1"
      :key="brand1.key"
    >
      <a :href="brand1.url" target="_blank">
        <img max-height="35" :src="brand1.src" alt="Logo Images" class="pr--30"
      /></a>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      brandImages: [
        {
          key: 1,
          src: require("../../assets/images/service/hygeia_logo.svg"),
          url: "https://www.hygeia.gr",
        },
        {
          key: 2,
          src: require("../../assets/images/service/metropolitan_hospital_logo.svg"),
          url: "https://www.metropolitan-hospital.gr",
        },
        {
          key: 3,
          src: require("../../assets/images/service/mitera_logo.svg"),
          url: "https://www.mitera.gr",
        },
        {
          key: 4,
          src: require("../../assets/images/service/metropolitan_general_logo.svg"),
          url: "https://www.metropolitan-general.gr",
        },
        {
          key: 5,
          src: require("../../assets/images/service/litw_logo.svg"),
          url: "https://www.leto.gr",
        },
        {
          key: 6,
          src: require("../../assets/images/service/creta_logo.svg"),
          url: "https://www.cic.gr",
        },
        {
          key: 7,
          src: require("../../assets/images/service/healthspot_logo.svg"),
          url: "https://healthspot.hhg.gr/",
        },
        {
          key: 8,
          src: require("../../assets/images/service/digitalclinic_logo.svg"),
          url: "https://www.digitalclinic.gr/el/",
        },
        {
          key: 9,
          src: require("../../assets/images/service/ivf.svg"),
          url: "/",
          style: {
            "padding-left": "12% !important",
            "padding-right": "12%"
          }
        },
        {
          key: 10,
          src: require("../../assets/images/service/alab.svg"),
          url: "/",
          style: {
            // "padding-left": "15% !important",
            "padding-right": "15%"
          }
        },
      ],
      brandImage1: [
        {
          key: 9,
          src: require("../../assets/images/service/hhg_logo_big.svg"),
          url: "https://www.hhg.gr/el/",
        },
      ],
    };
  },
};
</script>
<style scoped>
/* .justify-space-between{
  margin-left: 200px;
} */
.brand-img {
  max-height: 45px;
}
</style>
