<template>
  <v-layout row justify-space-between class="mt--40 mb--5">
    <v-flex
      style="z-index: 100; !important"
      sm2
      md2
      lg1
      xl1
      v-for="(brand, i) in brandImages"
      :key="i"
    >
      <a :href="brand.url" target="_blank">
        <img width="80" :src="brand.src" alt="Logo Images"
      /></a>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      brandImages: [
        {
          src: require("../../assets/images/service/hygeia_logo.svg"),
          url: "https://www.hygeia.gr",
        },
        {
          src: require("../../assets/images/service/metropolitan_hospital_logo.svg"),
          url: "https://www.metropolitan-hospital.gr",
        },
        {
          src: require("../../assets/images/service/mitera_logo.svg"),
          url: "https://www.mitera.gr",
        },
      ],
    };
  },
};
</script>
