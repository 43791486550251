<template>
  <v-layout row justify-space-between class="mt--5 mb--5">
    <v-flex
      style="z-index: 100; !important"
      sm2
      md2
      lg1
      xl1
      v-for="(brand, i) in brandImages"
      :key="i"
    >
      <a :href="brand.url" target="_blank">
        <img width="80" :src="brand.src" alt="Logo Images"
      /></a>
    </v-flex>
  </v-layout>
</template>


<script>
export default {
  data() {
    return {
      brandImages: [
        {
          src: require("../../assets/images/service/metropolitan_general_logo.svg"),
          url: "https://www.metropolitan-general.gr",
        },
        {
          src: require("../../assets/images/service/litw_logo.svg"),
          url: "https://www.leto.gr",
        },
        {
          src: require("../../assets/images/service/creta_logo.svg"),
          url: "https://www.cic.gr",
        },
      ],
    };
  },
};
</script>
