<template>
  <div>
    <div class="d-none d-md-block">
      <v-row class="pr-0">
        <v-col md="4" lg="3" sm="4" xl="2"> </v-col>
        <v-col md="8" lg="9" sm="8" xl="10">
          <div class=""><Brand /></div>
        </v-col>
      </v-row>
    </div>
    <div class="d-none d-sm-block d-md-none">
      <v-row class="mt--180 ml--30 mr--30">
        <v-col md="12">
          <div class=""><BrandMedium /></div>
          <div class=""><BrandMedium2 /></div>
        </v-col>
      </v-row>
    </div>
    <div class="d-block d-sm-none">
      <v-row class="mt--90 ml--5 mr--30">
        <v-col md="12">
          <div class="ml--50"><BrandSmall /></div>
          <div class="ml--50"><BrandSmall2 /></div>
          <div class="ml--50"><BrandSmall3 /></div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Brand from "@/components/brand/Brand";
import BrandSmall from "@/components/brand/BrandSmall";
import BrandMedium from "@/components/brand/BrandMedium";
import BrandMedium2 from "@/components/brand/BrandMedium2";
import BrandSmall2 from "@/components/brand/BrandSmall2";
import BrandSmall3 from "@/components/brand/BrandSmall3";

export default {
  components: {
    Brand,
    BrandSmall,
    BrandSmall2,
    BrandSmall3,
    BrandMedium,
    BrandMedium2,
  },
};
</script>
