<template>
  <v-layout row justify-space-between class="mt--30 mb--30">
     
    <v-flex
      style="z-index: 100; !important;"
      sm2
      md2
      lg1
      xl1 v-for="(brand, i) in brandImages"
      :key="i"
      class="px-2"
    >
      <a :href="brand.url" target="_blank">
        <img width="150" :src="brand.src" alt="Logo Images" :style="brand.style"
      /></a>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      brandImages: [
        {
          src: require("../../assets/images/service/hygeia_logo.svg"),
          url: "https://www.hygeia.gr",
        },
        {
          src: require("../../assets/images/service/metropolitan_hospital_logo.svg"),
          url: "https://www.metropolitan-hospital.gr",
        },
        {
          src: require("../../assets/images/service/mitera_logo.svg"),
          url: "https://www.mitera.gr",
        },
        {
          src: require("../../assets/images/service/metropolitan_general_logo.svg"),
          url: "https://www.metropolitan-general.gr",
        },
        {
          src: require("../../assets/images/service/ivf.svg"),
          url: "/",
          style: {
            "padding-left": "12% !important",
            "padding-right": "12%"
          }
        },
        {
          src: require("../../assets/images/service/alab.svg"),
          url: "/",
          style: {
            // "padding-left": "15% !important",
            "padding-right": "15%"
          }
        },
      ],
    };
  },
};
</script>
