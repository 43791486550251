<template>
  <div>
    <BrandChooseWindow />
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo-all-dark.png" />
    </Header>
    <div class="bg_image bg_image--16 d-none d-md-block">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100"></div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <div class="rn-column-area rn-section-gap mt--150"> -->
    <v-container>
      <v-row>
        <v-col xl="12" lg="12" md="12" sm="12" cols="12">
          <div class="single-service service__style--3 text-white mb--50">
            <div class="">
              <h3 class="heading-title" style="font-size: 30px">
                Πολιτική Απορρήτου
              </h3>

              <p>
                Οι Κλινικές, τα Διαγνωστικά Κέντρα και τα Πολυϊατρεία του
                Hellenic Healthcare Group (εφεξής HHG) λαμβάνουν σοβαρά υπόψη
                την προστασία της ιδιωτικότητας των ασθενών, των πελατών και των
                επισκεπτών τους. Για το λόγο αυτό, ακολουθούμε αυστηρά την
                ακόλουθη Πολιτική Προστασίας Προσωπικών Δεδομένων, η οποία
                διασφαλίζει το υψηλό επίπεδο των προσφερόμενων υπηρεσιών και
                τηρεί απαρέγκλιτα το ισχύον νομοθετικό πλαίσιο. Τα δεδομένα
                προσωπικού χαρακτήρα που σας αφορούν συλλέγονται και
                διατηρούνται για τον απαραιτήτως αναγκαίο χρόνο, για
                καθορισμένους, ρητούς και νόμιμους σκοπούς, τυγχάνουν σύννομης
                και θεμιτής επεξεργασίας με διαφανή τρόπο σύμφωνα πάντα με το
                ισχύον νομικό πλαίσιο και κατά τρόπο που εγγυάται την
                ακεραιότητα και την εμπιστευτικότητα αυτών. Τα δεδομένα αυτά
                είναι κάθε φορά κατάλληλα, συναφή, πρόσφορα και όχι περισσότερα
                από όσα απαιτούνται εν όψει των ανωτέρω σκοπών, είναι δε ακριβή
                και, εφόσον χρειάζεται, υποβάλλονται σε ενημέρωση.
              </p>
              <h3
                style="display: inline; font-size: 20px"
                class="heading-title"
              >
                Στοιχεία Hellenic Healthcare Group
              </h3>
              <p>
                Ο HHG για την παροχή υπηρεσιών υγείας διαθέτει τις κάτωθι
                Κλινικές / Διαγνωστικά Κέντρα / Πολυϊατρεία τα οποία λειτουργούν
                ως από κοινού Υπεύθυνοι Επεξεργασίας για τα δεδομένα προσωπικού
                χαρακτήρα που επεξεργάζονται, είτε αυτά είναι απλά, είτε ειδικής
                κατηγορίας ευαίσθητα δεδομένα Υγείας.
              </p>
              <p>Τα στοιχεία τους ακολουθούν αναλυτικά:</p>
              <p>
                Εμπορική Επωνυμία : ΔΙΑΓΝΩΣΤΙΚΟΝ ΚΑΙ ΘΕΡΑΠΕΥΤΙΚΟΝ ΚΕΝΤΡΟΝ ΑΘΗΝΩΝ
                "ΥΓΕΙΑ" ΑΝΩΝΥΜΗ ΕΤΑΙΡΕΙΑ<br />
                Διακριτικός Τίτλος : ΥΓΕΙΑ Α.Ε.<br />
                Έδρα : ΚΗΦΙΣΙΑΣ ΚΑΙ ΕΡΥΘΡΟΥ ΣΤΑΥΡΟΥ 4 ΜΑΡΟΥΣΙ<br />
                ΑΦΜ : 094027767
              </p>
              <p>
                Εμπορική Επωνυμία : ΠΕΡΣΕΥΣ ΑΝ. ΕΤΑΙΡΕΙΑ ΕΚΜ/ΣΗΣ & ΛΕΙΤΟΥΡΓΙΑΣ
                ΦΟΡΕΩΝ ΥΓ/ΚΗΣ ΜΕΡΙΜΝΑΣ<br />
                Διακριτικός Τίτλος : ΠΕΡΣΕΥΣ ΥΓΕΙΟΝΟΜΙΚΗ ΜΕΡΙΜΝΑ Α.Ε -
                METROPOLITAN<br />
                Έδρα : ΕΘ. ΜΑΚΑΡΙΟΥ 9 & ΕΛ. ΒΕΝΙΖΕΛΟΥ 1<br />
                ΑΦΜ : 099551880
              </p>
              <p>
                Εμπορική Επωνυμία : ΜΗΤΕΡΑ ΙΔΙΩΤΙΚΗ ΓΕΝΙΚΗ, ΜΑΙΕΥΤΙΚΗ,
                ΓΥΝΑΙΚΟΛΟΓΙΚΗ ΚΑΙ ΠΑΙΔΙΑΤΡΙΚΗ ΚΛΙΝΙΚΗ ΑΝΩΝΥΜΗ ΕΤΑΙΡΕΙΑ<br />
                Διακριτικός Τίτλος : ΜΗΤΕΡΑ Α.Ε.<br />
                Έδρα : ΕΡΥΘΡΟΥ ΣΤΑΥΡΟΥ 6 ΜΑΡΟΥΣΙ ΤΚ 15123<br />
                ΑΦΜ : 094039858
              </p>
              <p>
                Εμπορική Επωνυμία : ΜΕΤΡΟΠΟΛΙΤΑΝ ΤΖΕΝΕΡΑΛ ΧΟΣΠΙΤΑΛ Α. ΕΤΑΙΡΕΙΑ
                ΕΚΜ/ΣΗΣ & ΛΕΙΤΟΥΡΓΙΑΣ ΦΟΡΕΩΝ ΥΓ/ΚΗΣ ΜΕΡΙΜΝΑΣ<br />
                Διακριτικός Τίτλος : METROPOLITAN GENERAL Α.Ε.<br />
                Έδρα : ΛΕΩΦΟΡΟΣ ΜΕΣΟΓΕΙΩΝ 264 ΧΟΛΑΡΓΟΣ<br />
                ΑΦΜ : 094237943
              </p>
              <p>
                Εμπορική Επωνυμία : ΛΗΤΩ ΜΑΙΕΥΤΙΚΟ - ΓΥΝΑΙΚΟΛΟΓΙΚΟ ΚΑΙ
                ΧΕΙΡΟΥΡΓΙΚΟ ΚΕΝΤΡΟ ΑΝΩΝΥΜΗ ΕΤΑΙΡΕΙΑ<br />
                Διακριτικός Τίτλος : ΛΗΤΩ<br />
                Έδρα : ΜΟΥΣΩΝ 7-13 ΑΘΗΝΑ<br />
                ΑΦΜ : 094318509
              </p>
              <p>
                Εμπορική Επωνυμία : ΑΛΦΑ LAB ΙΔΙΩΤΙΚΟ ΔΙΑΓΝΩΣΤΙΚΟ ΕΡΓΑΣΤΗΡΙΟ
                ΙΑΤΡΙΚΗ ΑΝΩΝΥΜΗ ΕΤΑΙΡΕΙΑ<br />
                Διακριτικός Τίτλος : ΑΛΦΑ LAB Α.Ε.<br />
                Έδρα : Γ. ΑΝΑΣΤΑΣΙΟΥ 11 ΑΘΗΝΑ<br />
                ΑΦΜ : 094396905
              </p>
              <p>
                Εμπορική Επωνυμία : ΧΕΛΘ ΣΠΟΤ ΙΔΙΩΤΙΚΟ ΠΟΛΥΙΑΤΡΕΙΟ ΙΑΤΡΙΚΗ
                ΜΟΝΟΠΡΟΣΩΠΗ ΙΔΙΩΤΙΚΗ ΚΕΦΑΛΑΙΟΥΧΙΚΗ ΕΤΑΙΡΕΙΑ<br />
                Διακριτικός Τίτλος : HEALTH SPOT BY HHG SINGLE MEMBER PRIVATE
                COMPANY<br />
                Έδρα : Λεβίδου 16, Τ.Κ. 145 62, Κηφισιά<br />
                ΑΦΜ : 996954275
              </p>
              <p>
                Τα στοιχεία του Υπευθύνου Προστασίας Δεδομένων (DPO) για τις
                εταιρείες του HHG είναι : Δημήτρης Κολιός, Φλέμινγκ 14, 15123
                Μαρούσι, Τ: 210 686 7679
              </p>

              <h3 class="heading-title" style="font-size: 20px">
                ΑΝΤΙΚΕΙΜΕΝΟ
              </h3>
              <p>
                Με την παρούσα Πολιτική καθορίζονται οι όροι και οι προϋποθέσεις
                που τηρούνται από τον HHG για την προστασία εν γένει της
                ιδιωτικότητας των ασθενών, συνοδών, οικείων και κάθε είδους
                συμπαραστατών αυτών, τα προσωπικά δεδομένα των οποίων τυγχάνουν
                επεξεργασίας με σκοπό την παροχή υπηρεσιών υγείας και των
                χρηστών των εφαρμογών που δημιουργούνται από τις Κλινικές /
                Διαγνωστικά Κέντρα / Πολυϊατρεία του HHG. Ο σκοπός της παρούσας
                Πολιτικής είναι να σας ενημερώσουμε για το πώς συλλέγουμε,
                διατηρούμε και επεξεργαζόμαστε τις πληροφορίες που σας αφορούν,
                όπως τα προσωπικά σας στοιχεία που μας παρέχετε κατά την επιλογή
                σας να λάβετε υπηρεσίες υγείας από τον Όμιλό μας, είτε δεδομένα
                υγείας που προκύπτουν από την παροχή των υπηρεσιών μας σε εσάς.
              </p>
              <p>
                Ο Όμιλος διατηρεί το δικαίωμα να τροποποιεί και να
                αναπροσαρμόζει την παρούσα Πολιτική, όποτε κρίνει αυτό αναγκαίο,
                ενώ οι εκάστοτε αλλαγές τίθενται σε ισχύ από την ανάρτηση αυτών
                στην παρούσα ιστοσελίδα/εφαρμογή.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ
              </h3>

              <p>
                Ο HHG αγωνίζεται να διεξάγει τις επιχειρηματικές του δράσεις σε
                συμφωνία με τις αρχές της ιδιωτικότητας, καθώς πιστεύουμε ότι
                επιδεικνύουν την ακλόνητη δέσμευσή μας σε δεοντολογικές και
                υπεύθυνες πρακτικές. Αναγνωρίζουμε ότι η καινοτομία και οι νέες
                τεχνολογίες οδηγούν σε συνεχείς αλλαγές όσον αφορά τους
                κινδύνους, τις προσδοκίες και τη νομοθεσία, και γι’ αυτό
                ακολουθούμε τα πρότυπα ανάληψης ευθυνών για την ιδιωτικότητα και
                στοχεύουμε στην έγκαιρη προσαρμογή του πως τα εφαρμόζουμε ως
                απάντηση στις αλλαγές αυτές.
              </p>
              <p>
                Η παρούσα Πολιτική ισχύει, επίσης, και για όλα τα άτομα των
                οποίων τα δεδομένα επεξεργαζόμαστε, συμπεριλαμβανομένων, αλλά
                χωρίς να περιορίζεται σε πελάτες, υποψήφιους, τωρινούς και πρώην
                εργαζόμενους και τους εξαρτωμένους τους, μέλη της επιτροπής
                ηθικής και δεοντολογίας, συνεταίρους, επενδυτές και μετόχους,
                κρατικούς υπαλλήλους και άλλους ενδιαφερόμενους.
              </p>
              <p>
                <b>
                  Όλοι οι Εργαζόμενοι του Ομίλου και τα στελέχη της Διοίκησης
                  έχουν σημαντικές ευθύνες σχετικά με την προστασία της
                  ιδιωτικότητας τις οποίες οφείλουν να τηρούν</b
                >
              </p>

              <p>
                Nα γνωρίζουμε ότι ακούσια σφάλματα και εσφαλμένη κρίση σχετικά
                με την προστασία των δεδομένων μπορούν να προκαλέσουν κινδύνους
                για την ιδιωτικότητα των ατόμων και κινδύνους όσον αφορά την
                υπόληψη, τις διεργασίες, τη συμμόρφωση και τα οικονομικά του
                Ομίλου μας. Κάθε εργαζόμενος του Ομίλου μας, καθώς και άλλα
                άτομα τα οποία επεξεργάζονται δεδομένα για τις εταιρείες μας,
                είναι υπεύθυνοι για την κατανόηση και τήρηση των υποχρεώσεων
                τους έναντι στην παρούσα Πολιτική και τους υφιστάμενους νόμους.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Οι Αξίες μας και τα Πρότυπα μας σχετικά με την Ιδιωτικότητα
              </h3>

              <p>
                Τηρούμε τις αξίες μας σχετικά με την ιδιωτικότητα σε κάθε τι που
                κάνουμε και που περιλαμβάνει ανθρώπους, συμπεριλαμβανομένου του
                πώς εφαρμόζουμε τα πρότυπα ιδιωτικότητας. Οι τέσσερις αξίες
                ιδιωτικότητας περιλαμβάνουν:
              </p>
              <div class="ml--20">
                <p>
                  1.<span class="ml--20"
                    ><b>Σεβασμός</b> - Αναγνωρίζουμε ότι οι ανησυχίες σχετικά με
                    την ιδιωτικότητα συχνά σχετίζονται με τα ουσιώδη ερωτήματα
                    του ποιοι είμαστε, πώς βλέπουμε τον κόσμο και πώς ορίζουμε
                    τους εαυτούς μας. Έτσι, προσπαθούμε σκληρά να σεβόμαστε την
                    οπτική και τα ενδιαφέροντα των ατόμων και κοινωνιών και να
                    είμαστε δίκαιοι και διαφανείς στο πώς χρησιμοποιούμε και
                    μοιραζόμαστε πληροφορίες σχετικά με αυτά.</span
                  >
                </p>
                <p>
                  2.<span class="ml--20"
                    ><b>Εμπιστοσύνη</b> - Γνωρίζουμε ότι η εμπιστοσύνη είναι
                    ζωτικής σημασίας για την επιτυχία μας, και για αυτό
                    προσπαθούμε σκληρά να δημιουργούμε και να διατηρούμε την
                    εμπιστοσύνη των πελατών, εργαζομένων, ασθενών και άλλων
                    ενδιαφερόμενων, όσον αφορά το σεβασμό και την προστασία των
                    πληροφοριών που σχετίζονται με αυτούς.</span
                  >
                </p>
                <p>
                  3.<span class="ml--20"
                    ><b>Αποτροπή βλαβών</b> - Κατανοούμε ότι η κακή χρήση των
                    πληροφοριών που σχετίζονται με ανθρώπους μπορεί να
                    δημιουργήσει απτές και άυλες βλάβες για τα άτομα, και έτσι
                    προσπαθούμε να αποτρέπουμε φυσικές, οικονομικές βλάβες,
                    βλάβες στην υπόληψή τους ή άλλου είδους βλάβες που
                    σχετίζονται με την ιδιωτικότητα.</span
                  >
                </p>
                <p>
                  4.<span class="ml--20"
                    ><b>Συμμόρφωση</b> - Έχουμε μάθει ότι οι νόμοι και οι
                    κανονισμοί δε συνάδουν πάντα με τις ραγδαίες εξελίξεις της
                    τεχνολογίας, της ροής δεδομένων και των συσχετιζόμενων
                    αλλαγών στους κινδύνους και τις προσδοκίες της
                    ιδιωτικότητας. Έτσι προσπαθούμε σκληρά να συμμορφωνόμαστε με
                    το πνεύμα και τους κανονισμούς της ιδιωτικότητας και των
                    νόμων της προστασίας δεδομένων με τρόπο που επιδεικνύει
                    συνέπεια και λειτουργική επάρκεια για τις επιχειρηματικές
                    μας δράσεις σε παγκόσμιο επίπεδο.</span
                  >
                </p>
              </div>
              <br />
              <p>
                Ενσωματώνουμε τα πρότυπα ιδιωτικότητάς μας σε όλες τις
                δραστηριότητες, διεργασίες, τεχνολογίες και σχέσεις με τρίτα
                πρόσωπα που χρησιμοποιούν Προσωπικά Δεδομένα. <br /><b
                  >Σχεδιάζουμε ελέγχους ιδιωτικότητας στις επεξεργασίες και
                  τεχνολογίες μας που είναι συνεπείς με τις αξίες και τα πρότυπα
                  ιδιωτικότητάς μας και με την εφαρμοστέα νομοθεσία. </b
                >Οι οκτώ αρχές ιδιωτικότητας που περιγράφονται παρακάτω,
                συνοψίζουν τα πρότυπα ιδιωτικότητας και τις βασικές προϋποθέσεις
                για επεξεργασίες, δραστηριότητες και τις υποστηρικτικές τους
                τεχνολογίες σε υψηλό επίπεδο.
              </p>
              <div class="ml--20">
                <p>
                  1.<span class="ml--20"
                    ><b>Αναγκαιότητα</b> - Πριν τη συλλογή, χρήση ή διανομή
                    Προσωπικών Δεδομένων, καθορίζουμε και καταγράφουμε τον
                    ειδικό, έννομο επιχειρησιακό σκοπό για τον οποίο αυτό είναι
                    απαραίτητο.</span
                  >
                </p>
                <p>
                  2.<span class="ml--20"
                    ><b>Δικαιοσύνη </b> - Δεν επεξεργαζόμαστε Προσωπικά Δεδομένα
                    με τρόπους που είναι άδικοι για τα άτομα τα οποία τα
                    δεδομένα αφορούν.</span
                  >
                </p>
                <p>
                  3.<span class="ml--20"
                    ><b>Διαφάνεια </b> - Δεν επεξεργαζόμαστε Προσωπικά Δεδομένα
                    με τρόπους ή σκοπούς που δεν είναι διαφανείς.</span
                  >
                </p>
                <p>
                  4.<span class="ml--20"
                    ><b>Περιορισμός Σκοπού </b> - Χρησιμοποιούμε Προσωπικά
                    Δεδομένα μόνο σε συμφωνία με τις αρχές της Αναγκαιότητας και
                    της Διαφάνειας.</span
                  >
                </p>
                <p>
                  5.<span class="ml--20"
                    ><b>Ποιότητα Δεδομένων </b> - Διατηρούμε τα Προσωπικά
                    Δεδομένα ακριβή, ολόκληρα και ενημερωμένα, και σε συμφωνία
                    με την επιθυμητή χρήση τους.</span
                  >
                </p>
                <p>
                  6.<span class="ml--20"
                    ><b>Ασφάλεια </b> - Ενσωματώνουμε δικλείδες ασφαλείας για να
                    προστατέψουμε τα Προσωπικά Δεδομένα και τα Ευαίσθητα
                    Δεδομένα από απώλεια, εσφαλμένη χρήση, και μη εγκεκριμένη
                    πρόσβαση, αποκάλυψη ή καταστροφή.</span
                  >
                </p>
                <p>
                  7.<span class="ml--20"
                    ><b>Μεταφορά Δεδομένων </b> - Είμαστε υπεύθυνοι για τη
                    διατήρηση της ασφάλειας της ιδιωτικότητας των Προσωπικών
                    Δεδομένων όταν αυτά μεταφέρονται από ή προς άλλους
                    οργανισμούς ή σύνορα κρατών..</span
                  >
                </p>
                <p>
                  8.<span class="ml--20"
                    ><b>Νομικά Επιτρεπτό </b> - Επεξεργαζόμαστε Προσωπικά
                    Δεδομένα μόνο εάν πληρούνται οι απαιτήσεις της ισχύουσας
                    νομοθεσίας.</span
                  >
                </p>
              </div>
              <br />
              <h3 class="heading-title" style="font-size: 20px">ΟΡΙΣΜΟΙ</h3>

              <p>
                <b>«δεδομένα προσωπικού χαρακτήρα»</b> είναι κάθε πληροφορία που
                αφορά ταυτοποιημένο ή ταυτοποιήσιμο φυσικό πρόσωπο,
              </p>
              <p>
                <b>«γενετικά δεδομένα»</b> είναι τα δεδομένα προσωπικού
                χαρακτήρα που αφορούν γενετικά χαρακτηριστικά φυσικού προσώπου
                που κληρονομήθηκαν ή αποκτήθηκαν, όπως προκύπτουν, ιδίως, από
                ανάλυση βιολογικού δείγματος του εν λόγω φυσικού προσώπου και τα
                οποία παρέχουν μοναδικές πληροφορίες σχετικά με τη φυσιολογία ή
                την υγεία του εν λόγω φυσικού προσώπου,
              </p>
              <p>
                <b>«βιομετρικά δεδομένα»</b> είναι τα δεδομένα προσωπικού
                χαρακτήρα, τα οποία προκύπτουν από ειδική τεχνική επεξεργασία
                συνδεόμενη με φυσικά, βιολογικά ή συμπεριφορικά χαρακτηριστικά
                φυσικού προσώπου και τα οποία επιτρέπουν ή επιβεβαιώνουν την
                αδιαμφισβήτητη ταυτοποίηση του εν λόγω φυσικού προσώπου,
              </p>
              <p>
                <b>«δεδομένα που αφορούν στην υγεία»</b> είναι τα δεδομένα
                προσωπικού χαρακτήρα, τα οποία σχετίζονται με τη σωματική ή
                ψυχική υγεία ενός φυσικού προσώπου, περιλαμβανομένης της παροχής
                υπηρεσιών υγειονομικής φροντίδας και τα οποία αποκαλύπτουν
                πληροφορίες σχετικά με την κατάσταση της υγείας του,
              </p>
              <p>
                <b>«ειδικής κατηγορίας δεδομένα προσωπικού χαρακτήρα»</b> είναι
                μεταξύ άλλων τα γενετικά, βιομετρικά και τα δεδομένα που αφορούν
                στην υγεία,
              </p>
              <p>
                <b>«επεξεργασία προσωπικών δεδομένων»</b> σημαίνει κάθε πράξη ή
                σειρά πράξεων σε δεδομένα προσωπικού χαρακτήρα, όπως συλλογή,
                καταχώρηση, οργάνωση, διάρθρωση, αποθήκευση, προσαρμογή ή
                μεταβολή, ανάκτηση πληροφοριών, χρήση, κοινολόγηση με διαβίβαση,
                διάδοση ή κάθε άλλη μορφή διάθεσης, συσχέτιση, συνδυασμός,
                περιορισμός, διαγραφή ή καταστροφή,
              </p>
              <p>
                <b>«υπεύθυνος επεξεργασίας»</b> είναι το φυσικό ή νομικό
                πρόσωπο, η δημόσια αρχή, η υπηρεσία ή άλλος φορέας που, μόνα ή
                από κοινού με άλλα, καθορίζουν τους σκοπούς και τον τρόπο
                επεξεργασίας δεδομένων προσωπικού χαρακτήρα,
              </p>
              <p>
                <b>«εκτελών την επεξεργασία»</b> είναι το φυσικό ή νομικό
                πρόσωπο, η δημόσια αρχή, η υπηρεσία ή άλλος φορέας που
                επεξεργάζεται προσωπικά δεδομένα για λογαριασμό του υπευθύνου
                επεξεργασίας,
              </p>
              <p>
                <b>«παραβίαση δεδομένων προσωπικού χαρακτήρα»:</b> η παραβίαση
                της ασφάλειας που οδηγεί σε τυχαία ή παράνομη καταστροφή,
                απώλεια, μεταβολή, άνευ άδειας κοινολόγηση ή πρόσβαση δεδομένων
                προσωπικού χαρακτήρα που διαβιβάστηκαν, αποθηκεύτηκαν ή
                υποβλήθηκαν κατ' άλλο τρόπο σε επεξεργασία.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                ΝΟΜΙΚΟ ΠΛΑΙΣΙΟ ΠΡΟΣΤΑΣΙΑΣ ΔΕΔΟΜΕΝΩΝ ΠΡΟΣΩΠΙΚΟΥ ΧΑΡΑΚΤΗΡΑ
              </h3>
              <p>
                Ως «νομικό πλαίσιο προστασίας προσωπικών δεδομένων» στο πλαίσιο
                της παρούσας Πολιτικής νοείται ο υπ’ αρ. 2016/679 Γενικός
                Κανονισμός Προστασίας Προσωπικών Δεδομένων του Ευρωπαϊκού
                Κοινοβουλίου και του Συμβουλίου για την προστασία των φυσικών
                προσώπων έναντι της επεξεργασίας των δεδομένων προσωπικού
                χαρακτήρα και για την ελεύθερη κυκλοφορία των δεδομένων αυτών
                και κάθε νόμος ή κανονισμός που έχει εκδοθεί σε συνέχεια ή για
                την εφαρμογή του ανωτέρω Γενικού Κανονισμού, καθώς και κάθε
                εθνικός νόμος που ισχύει και εφαρμόζεται και αφορά την
                επεξεργασία και την προστασία των προσωπικών δεδομένων
                γενικότερα και ειδικότερα στον χώρο της παροχής υπηρεσιών
                υγείας.
              </p>
              <p>
                Ενδεικτικά, αναφέρουμε ότι εφαρμόζονται, μεταξύ άλλων, οι
                ακόλουθοι νόμοι ως ισχύουν τροποποιηθέντες :
              </p>
              <div class="ml--20">
                <ul>
                  <li>
                    Ν.3418/2005 Κώδικας Ιατρικής Δεοντολογίας,
                  </li>
                  <li>
                    Ν.2071/1992 Εκσυγχρονισμός και Οργάνωση Συστήματος Υγείας,
                  </li>
                  <li>
                    Ν.2619/1998 Σύμβαση Οβιέδο
                  </li>
                  <li>
                    Σχετικές Κανονιστικές Πράξεις των αρμόδιων Ανεξάρτητων
                    Διοικητικών Αρχών
                  </li>
                  <li>
                    Γενικός Κανονισμός Προστασίας Δεδομένων 2016/679
                  </li>
                  <li>
                    Εφαρμοστικός Νόμος 4624/2019 για την προστασία προσωπικών
                    δεδομένων
                  </li>
                  <li>
                    Υφιστάμενη Νομοθεσία σχετικά με τα κατεπείγοντα μέτρα
                    αντιμετώπισης των αρνητικών συνεπειών της εμφάνισης του
                    κορωνοϊού COVID-19 και της ανάγκης περιορισμού της διάδοσής
                    του
                  </li>
                </ul>
              </div>
              <h3 class="heading-title" style="font-size: 20px">
                ΣΚΟΠΟΣ ΕΠΕΞΕΡΓΑΣΙΑΣ ΤΩΝ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ ΣΑΣ
              </h3>
              <p>
                Σύμφωνα με το ανωτέρω νομικό πλαίσιο, ο HHG συλλέγει και
                επεξεργάζεται προσωπικά δεδομένα ασθενών, συνοδών των ασθενών ή
                χρηστών των ιστοσελίδων των εταιρειών του για τους κατωτέρω
                σκοπούς και μόνο στο απολύτως αναγκαίο μέτρο για την
                αποτελεσματική εξυπηρέτηση των σκοπών αυτών. Τα δεδομένα αυτά
                είναι κάθε φορά συναφή, πρόσφορα και όχι περισσότερα από όσα
                απαιτούνται εν όψει των κατωτέρω σκοπών, είναι δε ακριβή και,
                εφόσον χρειάζεται, υποβάλλονται σε ενημέρωση-επικαιροποίηση. Ο
                HHG δύναται να επεξεργάζεται δεδομένα προσωπικού χαρακτήρα,
                εφόσον η επεξεργασία είναι απαραίτητη για μία τουλάχιστον από
                τις ακόλουθες νομικές βάσεις, ήτοι :
              </p>
              <div class="ml--20">
                <ul>
                  <li>
                    για την εκτέλεση της μεταξύ ημών σύμβασης ή για να ληφθούν
                    μέτρα σε συνέχεια αιτήσεώς σας πριν από τη σύναψη σύμβασης,
                    ή
                  </li>
                  <li>
                    προκειμένου να συμμορφωθεί σε έννομη υποχρέωσή του, ή
                  </li>
                  <li>
                    για τους σκοπούς των εννόμων συμφερόντων του, ή
                  </li>
                  <li>
                    όταν έχετε δώσει τη συγκατάθεσή σας, ή
                  </li>
                  <li>
                    για τη διαφύλαξη των ζωτικών σας συμφερόντων, ή
                  </li>
                  <li>
                    για την εκπλήρωση καθήκοντος που εκτελείται προς το δημόσιο
                    συμφέρον, ή
                  </li>
                  <li>
                    για την εκτέλεση δικαιωμάτων και υποχρεώσεων που προκύπτουν
                    από το δίκαιο κοινωνικής ασφάλισης, ή
                  </li>
                  <li>
                    για τη θεμελίωση, άσκηση ή υποστήριξη νομικών αξιώσεων ή
                    όταν τα δικαστήρια ενεργούν υπό τη δικαιοδοτική τους
                    ιδιότητα, ή
                  </li>
                  <li>
                    για σκοπούς προληπτικής ή επαγγελματικής ιατρικής, ιατρικής
                    διάγνωσης, παροχής υγειονομικής περίθαλψης ή θεραπείας ή
                    διαχείρισης υγειονομικών συστημάτων
                  </li>
                </ul>
              </div>
              <p>
                α. Ο HHG διατηρεί και επεξεργάζεται τα απλά και ευαίσθητα
                προσωπικά δεδομένα που παρέχετε εσείς ή έτερο πρόσωπο με την
                εξουσιοδότησή σας, με σκοπό την εκτέλεση της σύμβασης παροχής
                υπηρεσιών υγείας την οποία έχετε υπογράψει ή έχει υπογράψει
                έτερο φυσικό ή νομικό πρόσωπο για λογαριασμό σας ή/και για τη
                διαφύλαξη ζωτικού συμφέροντός σας ή/και για την εκπλήρωση
                έννομης υποχρέωσης ή συμφέροντος της εκάστοτε εταιρείας του
                Ομίλου ή/και βάση της συγκαταθέσεώς σας και δύναται να τα
                διαβιβάσει εντός ή εκτός της Ευρωπαϊκής Ένωσης σε ιδιωτικούς
                ή/και δημόσιους ασφαλιστικούς φορείς, συνεργάτες/εκτελούντες την
                επεξεργασία, ή/και αρμόδιες δικαστικές, αστυνομικές ή
                φορολογικές Αρχές σύμφωνα με το ισχύον νομικό πλαίσιο. Ο HHG
                διατηρεί και επεξεργάζεται ειδικής κατηγορίας δεδομένα, ήτοι
                ιατρικό ιστορικό, ιατρικές εξετάσεις, ιατρικές πράξεις που
                προσκομίζετε εσείς ο ίδιος ή έτερο φυσικό ή νομικό πρόσωπο για
                λογαριασμό σας και τα ιατρικά δεδομένα που προκύπτουν από την
                παροχή των ιατρικών υπηρεσιών – υπηρεσιών υγείας, με σκοπό την
                παροχή ιατρικών υπηρεσιών – υπηρεσιών υγείας με βάση την παροχή
                υπηρεσιών προληπτικής ή επαγγελματικής ιατρικής, ιατρική
                διάγνωση, τη διαφύλαξη των ζωτικών σας συμφερόντων, ή/και τη
                ρητή συγκατάθεσή σας. Τα ανωτέρω δεδομένα για τους ανωτέρω
                σκοπούς ο HHG δύναται να τα διαβιβάσει εντός ή εκτός της
                Ευρωπαϊκής Ένωσης σε ιδιωτικούς ή δημόσιους ασφαλιστικούς φορείς
                σύμφωνα με τη δική σας έννομη σχέση με αυτούς, σε δίκτυο Ιατρών
                που παρέχουν ανεξάρτητες υπηρεσίες στον Όμιλό μας, σε συνεργάτες
                που ενεργούν για λογαριασμό της εκάστοτε εταιρείας, σύμφωνα με
                τις μεταξύ ημών συμβάσεις, για το σκοπό της παροχής των
                υπηρεσιών υγείας.
              </p>
              <p>
                β. Ο HHG σύμφωνα με όσα προβλέπονται στο υφιστάμενο νομικό
                πλαίσιο, δύναται να επεξεργάζεται και να διαβιβάζει απλά ή
                ειδικής κατηγορίας προσωπικά δεδομένα του ασθενούς σε δικηγορικά
                γραφεία για τη θεμελίωση, άσκηση ή υποστήριξη νομικών αξιώσεων ή
                όταν τα δικαστήρια ενεργούν υπό τη δικαιοδοτική τους ιδιότητα σε
                αρμόδιες Αρχές, καθώς και για λόγους έννομης υποχρέωσης ή
                δημοσίου συμφέροντος, όπως ο νόμος ορίζει. Επίσης, ο HHG δύναται
                να επεξεργάζεται και να διαβιβάζει τα απλά δεδομένα του ασθενούς
                ή/και του υπόχρεου/συνοδού του για τη συμμόρφωσή του με έννομη
                υποχρέωση, καθώς και κατά περίπτωση καθήκον προς το δημόσιο
                συμφέρον στις αρμόδιες αστυνομικές, δικαστικές, διοικητικές,
                φορολογικές Αρχές εντός και εκτός Ευρωπαϊκής Ένωσης, κατόπιν
                έγκυρου αιτήματος αυτών. Επίσης, έχει έννομη υποχρέωση να
                πραγματοποιεί κάθε απαραίτητο εσωτερικό έλεγχο σε δεδομένα
                προσωπικού χαρακτήρα που σας αφορούν, σύμφωνα με τις εσωτερικές
                του διαδικασίες, όταν κατά νόμο προβλέπεται ή ορίζεται.
              </p>
              <p>
                γ. Ο HHG σύμφωνα με όσα προβλέπονται στο νομικό πλαίσιο, δύναται
                να διαβιβάζει για την είσπραξη και εξόφληση των οφειλών που
                έχουν προκύψει από την παροχή των ιατρικών υπηρεσιών – υπηρεσιών
                υγείας, απλά και ειδικής κατηγορίας προσωπικά δεδομένα σας σε
                δικηγορικά γραφεία για τη θεμελίωση, άσκηση ή υποστήριξη νομικών
                αξιώσεων.
              </p>
              <p>
                δ. Ο HHG κατόπιν σχετικής θετικής συγκαταθέσεώς σας, δύναται να
                επεξεργάζεται τα δεδομένα προσωπικού χαρακτήρα που σας αφορούν
                με σκοπό την ανάπτυξη, βελτίωση και προώθηση των υπηρεσιών του,
                καθώς και την παροχή προνομίων.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                ΧΡΟΝΟΣ ΔΙΑΤΗΡΗΣΗΣ ΤΩΝ ΔΕΔΟΜΕΝΩΝ
              </h3>
              <p>
                Ο HHG υποχρεούται να τηρεί έγγραφα ή ηλεκτρονικά αρχεία για το
                χρονικό διάστημα που προβλέπει η εθνική νομοθεσία. Συγκεκριμένα,
                όπως ορίζει ο Κώδικας Ιατρικής Δεοντολογίας (Ν.3418/2005, ΦΕΚ Α
                287/28.11.2005), «Άρθρο 14§4 : Η υποχρέωση διατήρησης των
                ιατρικών αρχείων ισχύει: α) στα ιδιωτικά ιατρεία και τις λοιπές
                μονάδες πρωτοβάθμιας φροντίδας υγείας του ιδιωτικού τομέα, για
                μία δεκαετία από την τελευταία επίσκεψη του ασθενή και β) σε
                κάθε άλλη περίπτωση, για μία εικοσαετία από την τελευταία
                επίσκεψη του ασθενή.»
              </p>
              <p>
                Τα στοιχεία που διατηρούνται για την εμπορική προώθηση προϊόντων
                ή υπηρεσιών ή/και την παροχή προνομίων θα διαγράφονται έξι μήνες
                μετά από την ολοκλήρωση της ενέργειας.
              </p>
              <p>
                Τα βιογραφικά σημειώματα που συγκεντρώνονται από τις αρμόδιες
                Διευθύνσεις Ανθρώπινου Δυναμικού, διατηρούνται για ένα έτος και
                στη συνέχεια καταστρέφονται σύμφωνα με την πολιτική καταστροφής
                που διαθέτει ο HHG για τις εταιρείες του.
              </p>
              <p>
                Τα φορολογικά στοιχεία διατηρούνται σύμφωνα με τη φορολογική
                νομοθεσία.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                ΤΑ ΔΙΚΑΙΩΜΑΤΑ ΣΑΣ ΑΝΑΦΟΡΙΚΑ ΜΕ ΤΗΝ ΠΡΟΣΤΑΣΙΑ ΠΡΟΣΩΠΙΚΩΝ
                ΔΕΔΟΜΕΝΩΝ
              </h3>
              <p>
                Η νομοθεσία για την προστασία των προσωπικών δεδομένων σας
                παρέχει τα ακόλουθα δικαιώματα, τα οποία μπορείτε κατ’ αρχήν να
                ασκήσετε δωρεάν και με βάση όσα προβλέπονται στο νομικό πλαίσιο
                :
              </p>
              <div class="ml--20">
                <ul>
                  <li>
                    <b> Δικαίωμα πρόσβασης,</b> ήτοι να ενημερώνεστε για το ποια
                    δεδομένα σας έχει συλλέξει και υφίστανται επεξεργασία από
                    τον HHG, την προέλευσή τους, τους σκοπούς και τη νομική βάση
                    επεξεργασίας αυτών, τους τυχόν αποδέκτες ή τις κατηγορίες
                    αποδεκτών των προσωπικών δεδομένων, ιδίως σε τρίτες χώρες
                    καθώς και το χρόνο τήρησης τους.
                  </li>
                  <li>
                    <b> Δικαίωμα διόρθωσης</b> τυχόν ανακριβών προσωπικών
                    δεδομένων σας, ώστε να καταστούν ακριβή, υποβάλλοντας στον
                    HHG σχετική δήλωση με τα ακριβή προσωπικά δεδομένα σας.
                  </li>
                  <li>
                    <b> Δικαίωμα συμπλήρωσης</b> τυχόν ελλιπών προσωπικών
                    δεδομένων σας, ώστε να καταστούν πλήρη, υποβάλλοντας στον
                    HHG σχετική δήλωση με τα πλήρη προσωπικά δεδομένα σας.
                  </li>
                  <li>
                    <b> Δικαίωμα διαγραφής</b> των προσωπικών σας δεδομένων στις
                    εξής περιπτώσεις :
                    <div class="ml--20">
                      <ul style="list-style-type: circle;">
                        <li>
                          όταν τα προσωπικά σας δεδομένα δεν είναι πλέον
                          απαραίτητα σε σχέση με τους σκοπούς για τους οποίους
                          συλλέχθηκαν ή υποβλήθηκαν κατ’ άλλο τρόπο σε
                          επεξεργασία,
                        </li>
                        <li>
                          όταν ανακαλείτε τη συγκατάθεση σας επί της οποίας
                          βασίσθηκε η επεξεργασία των προσωπικών δεδομένων σας
                          και δεν υπάρχει άλλη νομική βάση για την επεξεργασία
                        </li>
                        <li>
                          όταν τα προσωπικά δεδομένα σας υποβλήθηκαν σε
                          επεξεργασία χωρίς την ύπαρξη της απαραίτητης νομικής
                          βάσης
                        </li>
                        <li>
                          όταν προβλέπεται από το νόμο η υποχρέωση διαγραφής των
                          προσωπικών δεδομένων σας
                        </li>
                        <li>
                          όταν έχουν συλλεγεί με τη προσφορά υπηρεσιών της
                          κοινωνίας των πληροφοριών προσωπικά δεδομένα παιδιού,
                          κατόπιν συγκατάθεσης του ή η συγκατάθεση παρέχεται ή
                          εγκρίνεται από το πρόσωπο που έχει τη γονική μέριμνα
                          του παιδιού.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <b> Δικαίωμα περιορισμού της επεξεργασίας</b> των προσωπικών
                    δεδομένων σας, στις εξής περιπτώσεις:
                    <div class="ml--20">
                      <ul style="list-style-type: circle;">
                        <li>
                          αμφισβητείτε την ακρίβεια των προσωπικών δεδομένων σας
                          και μέχρι να επαληθεύσει ο HHG την ακρίβεια τους
                        </li>
                        <li>
                          όταν αντί της διαγραφής, ζητάτε τον περιορισμό της
                          επεξεργασίας των προσωπικών δεδομένων σας
                        </li>
                        <li>
                          όταν ο HHG δεν χρειάζεται πλέον τα προσωπικά δεδομένα
                          σας για τους σκοπούς επεξεργασίας, αλλά τα προσωπικά
                          δεδομένα αυτά απαιτούνται από εσάς για τη θεμελίωση,
                          την άσκηση ή την υποστήριξη νομικών αξιώσεων.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <b> Δικαίωμα εναντίωσης – αντίταξης</b> στην επεξεργασία των
                    δεδομένων σας, εκτός εάν υφίστανται επιτακτικοί και νόμιμοι
                    λόγοι για την επεξεργασία, οι οποίοι υπερισχύουν των
                    συμφερόντων, των δικαιωμάτων και των ελευθεριών σας ή για τη
                    θεμελίωση, άσκηση ή υποστήριξη νομικών αξιώσεων του HHG.
                  </li>
                  <li>
                    <b> Δικαίωμα φορητότητας,</b> ήτοι να λάβετε και να
                    διαβιβάσετε σε άλλον υπεύθυνο επεξεργασίας τα προσωπικά
                    δεδομένα σας, τα οποία έχετε παράσχει στις Κλινικές, τα
                    Διαγνωστικά Κέντρα και τα Πολυϊατρεία του HHG σε κατάλληλο
                    μορφότυπο, εφόσον η επεξεργασία των προσωπικών δεδομένων σας
                    έχει λάβει χώρα κατόπιν συγκατάθεσής σας ή υπήρξε απαραίτητη
                    για την εκτέλεση της μεταξύ ημών σύμβασης.
                  </li>
                  <li>
                    <b> Δικαίωμα ανάκλησης της συγκατάθεσης</b> (χωρίς
                    αναδρομική ισχύ) που έχετε παράσχει για ζήτημα σχετιζόμενο
                    με την προστασία απλών προσωπικών δεδομένων και δεδομένων
                    υγείας.
                  </li>
                </ul>
              </div>
              <p>
                <b>
                  Τα εν λόγω δικαιώματα μπορεί να περιοριστούν λόγω υποχρέωσης
                  εφαρμογής άλλου νόμου, όπως για παράδειγμα στην περίπτωση που
                  αιτηθείτε τη διαγραφή δεδομένων, ενώ υποχρεούμαστε να τα
                  διατηρήσουμε βάσει νόμου.</b
                >
              </p>
              <p>
                Για όλα τα ανωτέρω και για την επίλυση οιασδήποτε απορίας
                αναφορικά με την ισχύουσα νομοθεσία για τα προσωπικά δεδομένα,
                μπορείτε να επικοινωνήσετε με τον HHG με τους εξής τρόπους :
              </p>
              <div class="ml--20">
                <ul>
                  <li>
                    μέσω της ηλεκτρονικής φόρμας επικοινωνίας στο <a href="mailto:gdpr@hygeia.gr">gdpr@hygeia.gr</a>
                    για Υγεία, Μητέρα, Λητώ και Άλφα Lab,
                    <a href="mailto:dataprotection@metropolitan-hospital.gr">dataprotection@metropolitan-hospital.gr</a> για Metropolitan
                    Hospital, Metropolitan General και τα Health Spot
                  </li>
                  <li>
                    με επιστολή προς τον Υπεύθυνο Προστασίας Δεδομένων του HHG
                    <b>(Φλέμινγκ 14, 15123 Μαρούσι)</b>
                  </li>
                  <li>
                    Ο HHG θα απαντήσει δωρεάν στην Αίτησή σας, χωρίς καθυστέρηση
                    και σε κάθε περίπτωση εντός μηνός από την παραλαβή του
                    αιτήματος, εκτός εξαιρετικών περιπτώσεων, οπότε η ανωτέρω
                    προθεσμία μπορεί να παραταθεί κατά δυο ακόμη μήνες, εφόσον
                    απαιτείται, λαμβανομένων υπόψη της πολυπλοκότητας του
                    αιτήματος ή και του αριθμού των αιτημάτων. Ο HHG θα σας
                    ενημερώσει για τυχόν παράταση εντός μηνός από την παραλαβή
                    του αιτήματος, καθώς και για τους λόγους της καθυστέρησης.
                  </li>
                  <li>
                    Εάν δεν καθίσταται δυνατή η ικανοποίηση του αιτήματος σας, ο
                    HHG θα σας ενημερώσει χωρίς καθυστέρηση και το αργότερο
                    εντός μηνός από την παραλαβή του αιτήματος, για τους
                    σχετικούς λόγους και για τη δυνατότητα να υποβάλλετε
                    καταγγελία στην Αρχή Προστασίας Δεδομένων Προσωπικού
                    Χαρακτήρα, καθώς και για το δικαίωμα σας να προσφύγετε
                    ενώπιον των αρμόδιων δικαστικών αρχών.
                  </li>
                  <li>
                    Εάν το αίτημα σας κριθεί από τον HHG ως προδήλως αβάσιμο ή
                    υπερβολικό, δύναται να επιβάλει την καταβολή εύλογου και
                    ανάλογου τέλους, λαμβάνοντας υπόψη τα διοικητικά έξοδα για
                    την ικανοποίηση του ή να αρνηθεί να δώσει συνέχεια στο
                    αίτημα σας.
                  </li>
                </ul>
              </div>
              <h3 class="heading-title" style="font-size: 20px">
                ΔΙΚΑΙΩΜΑ ΚΑΤΑΓΓΕΛΙΑΣ
              </h3>
              <p>
                Σε περίπτωση που θεωρείτε ότι προσβάλλονται τα δικαιώματά σας
                που αφορούν στην προστασία των προσωπικών σας δεδομένων,
                διατηρείτε το δικαίωμα υποβολής καταγγελίας στην Αρχή Προστασίας
                Δεδομένων Προσωπικού Χαρακτήρα (Λ. Κηφισίας 1-3, Τ.Κ. 115 23,
                Αθήνα, τηλ.: +30 2106475600, email : <a href="mailto:contact@dpa.gr">contact@dpa.gr</a>)
              </p>
              <p>
                Επίσης έχετε δικαίωμα προσφυγής στις αρμόδιες δικαστικές αρχές
                για την προστασία των προσωπικών δεδομένων σας.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                ΜΕΤΡΑ ΑΣΦΑΛΕΙΑΣ
              </h3>
              <p>
                Ο HHG έχει λάβει τα κατάλληλα τεχνικά κα οργανωτικά μέτρα
                ασφάλειας προκειμένου να διασφαλίζεται η εφαρμογή της νομοθεσίας
                και το κατάλληλο επίπεδο ασφάλειας των προσωπικών σας δεδομένων
                και έχει εκπαιδεύσει προσηκόντως το προσωπικό του και όλο το
                δίκτυο των συνεργαζόμενων με αυτό Ιατρών μέσω των Πολιτικών και
                των Διαδικασιών Προστασίας των Προσωπικών Δεδομένων και δεσμεύει
                όλους τους συνεργάτες του, οι οποίοι ενεργούν για λογαριασμό του
                ως Εκτελούντες την Επεξεργασία με συμβάσεις (Συμφωνητικό
                Επεξεργασίας Προσωπικών Δεδομένων – Data Protection Agreement)
                που διέπονται από τα εχέγγυα και τις διασφαλίσεις του ΓΚΠΔ.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                NEWSLETTER
              </h3>
              <p>
                Κατά την παραχώρηση της διεύθυνσης ηλεκτρονικού ταχυδρομείου
                σας, μας δίνετε και τη συγκατάθεσή σας για τη λήψη από εμάς
                ηλεκτρονικών μηνυμάτων με μόνο σκοπό τη διαφήμιση και την
                απευθείας προώθηση προϊόντων ή/και υπηρεσιών μας μέσω
                ενημερωτικού δελτίου (newsletter). Το email σας θα
                χρησιμοποιείται μόνο από τον HHG και από τον συνεργάτη που
                ενεργεί για λογαριασμό του για την αποστολή των δελτίων. Σε κάθε
                τέτοιο μήνυμα ηλεκτρονικού ταχυδρομείου, θα σας κοινοποιούμε
                ευδιάκριτα και σαφώς την ταυτότητά μας και θα σας δίνουμε τη
                δυνατότητα να αντιτάσσεστε και να ζητάτε, με εύκολο τρόπο και
                δωρεάν, τον τερματισμό της επικοινωνίας και τη διαγραφή των
                δεδομένων σας από την εν λόγω βάση.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                COOKIES
              </h3>
              <p>
                Η ιστοσελίδα μας λειτουργεί με ηλεκτρονικά μπισκότα. Για
                περισσότερες πληροφορίες παρακαλούμε όπως επισκεφθείτε τον
                παρακάτω σύνδεσμο σχετικά με την από μέρους μας χρήση
                ηλεκτρονικών μπισκότων
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <p></p>
    </v-container>
    <!-- </div> -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/HeaderOnePage";
import Footer from "@/components/footer/FooterTwo";
import BrandChooseWindow from "@/components/brand/BrandChooseWindow";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Header,
    Footer,
    BrandChooseWindow,
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["token"]),
  },
  async created() {
    await this.getUser();
  },
  methods: {
    ...mapActions(["getUser"]),
    ...mapActions(["getToken"]),
  },
};
</script>
<style scoped>
.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 55px;
}
</style>
